/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import NarrowBanner from '../components/NarrowBanner'
import HubspotForm from '../components/Form/HubspotForm'

import EmployeeSmiling from '../images/people/employee-smiling-black-and-white.jpg'
import EmployeeSmilingMobile from '../images/people/employee-smiling-black-and-white-mobile.jpg'

import { Billboard, LinkButton, Typography } from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'
import Footer from '../components/Footer'
import footerData from '../data/footerData'
import headerData from '../data/headerData'

const Home = () => {
  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Contact Us | Clearlink',
          description:
            "Got a question? We'd love to hear from you. Send us a message and we'll respond as soon as possible.",
          canonical: 'https://www.clearlink.com/contact/',
          robots: 'follow,index',
        },
        path: '/contact',
        hideStickyCTA: false,
        spanish: false,
        sections: [],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlink.com',
          siteName: 'Clearlink',
          alternateName: 'Clearlink',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Contact Us | Clearlink',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(),
    main: (
      <>
        <Billboard
          backgroundColor="dark"
          alignImageToBottom
          mobileAlignImageToBottom
          className="content-transparent-desktop mobile-image-full"
          variant="full"
          image={
            <img
              src={EmployeeSmiling}
              alt="employees working in an office environment"
            />
          }
          mobileImage={
            <img
              src={EmployeeSmilingMobile}
              alt="employees working in an office environment"
            />
          }
          mainContent={
            <div
              data-sal="slide-right"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h1">
                Let's Connect<span className="blue-punctuation-large">.</span>
              </Typography>
              <Typography variant="h4" style={{ marginBottom: '26px' }}>
                Got a question? We'd love to hear from you. Send us a message
                and we'll respond as soon as possible.
              </Typography>
            </div>
          }
        />

        <HubspotForm heading="Send us a message" punctuation="." />

        <NarrowBanner
          backgroundColor="#000000"
          heading={
            <div
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h5" className="s2">
                HR inquiries for current and former employees
              </Typography>
            </div>
          }
          button={
            <LinkButton
              to="mailto:hr@clearlink.com"
              color="primary"
              data-sal="slide-left"
              data-sal-delay="300"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              Email Us
            </LinkButton>
          }
        />
      </>
    ),
    disableBreadcrumbs: false,
  }

  return <Layout {...layoutProps} />
}
export default Home
